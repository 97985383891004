import React from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import { InlineWidget } from "react-calendly";
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";


const IndexPage = () => {
  const {t} = useTranslation();
  return (
      <Layout>
        <Seo
            title={t('Calendar')}
            description={t('Calendar')}
        />
        <div className="contact consultation">
          <section>
            <div className="container">
              <h1 className={'headline'}><Trans>consult_headline</Trans></h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center">
                  <div>
                    <h2><Trans>consult_subhead</Trans></h2>
                    <p><Trans>consult_subhead_copy</Trans></p>
                  </div>

                  <div class="buttons hide-on-mobile">
                    <p><Trans>consult_other_ways</Trans></p>
                    <div>
                      <a href="tel:+1 800 989 0201" className="btn btn--orange consult-call"><Trans>call</Trans></a>
                      <CustomLocalizedLink goto={'/contact/?help=purchaseLocks&choice=email'} label={t('email')} cls={'btn btn--orange consult-email'}/>
                    </div>
                  </div>
                </div>
                <div className="section-delimeter spacer"></div>
                <div className="grid-two-col-item-single center">
                  <InlineWidget
                      url=" https://calendly.com/harmony-carrillo-digilock/digilock-consultation?hide_event_type_details=1&text_color=333333&primary_color=ff6020"
                      styles={{
                        height: '500px',
                        minWidth: '320px'
                      }}
                  />
                  <div className="buttons hide-on-desktop">
                    <p><Trans>consult_other_ways</Trans></p>
                    <div>
                      <a href="tel:+1 800 989 0201" className="btn btn--orange consult-call"><Trans>call</Trans></a>
                      <CustomLocalizedLink goto={'/contact/?help=purchaseLocks&choice=email'} label={t('email')} cls={'btn btn--orange consult-email'}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
